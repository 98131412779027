.container-modal-title{   
    width: 100%;;
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    justify-content: flex-start;
}

.green-stepper{
    color: #779F60;
}