.form-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background-color: #fbf3ef;
  padding: 20px 10px;
  gap: 20px;
  &-first-box {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  &-second-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-payment-title {
    padding: 10px 0px;
    text-align: center;
  }
}

.payment-container-page {
  //background-color: #fbf3ef;
}

.payment-form-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sell-twelve {
  width: 500px;
  height: 300px;
  filter: contrast(110%);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.verify-pay-info{
  background: #56ab2f !important;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #a8e063, #56ab2f) !important;  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #a8e063, #56ab2f) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.payment_form{
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

.MuiInputLabel-formControl{
  font-size: 14px !important;
}

input[type='text'], input[type='number']{
  box-sizing: content-box;
}
}

.payment_stepper_content{
  width: 100%;
  display: flex;
  flex-direction: row;
}
