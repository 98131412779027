.notification-update {
  position: fixed;
  top: 40px;
  right: 20px;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  z-index: 999;
  display: none; 
  align-items: center;
  gap: 10px;
  transition: opacity 0.5s;
}

.notification-update.visible {
  display: flex;
  opacity: 1;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
