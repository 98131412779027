.recording-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 10px;
    border-top: 0.7px solid #c3c3c3;
    margin-top: 5px;

    &-info{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        flex: 1 1 0%;
    }

    &-options{
        display: flex;
        flex-direction: column;
        column-gap: 6px;
        width: fit-content;
        padding: 0px 12px;
        
    }
}
.share-text{
    color: #224fa4e6;
    padding: 0px;
}

.share-container{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.link-button{
    cursor: pointer;
    font-size: 15px;
    color: #224fa4e6;
    font-weight: bold;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 3px;
}

.link-button:hover{
    text-decoration: underline;
    font-size: 16px;
}


.watch-button{
    cursor: pointer;
    font-size: 15px;
    color: #779F60;
    font-weight: bold;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 3px;
}

.watch-button:hover{
    text-decoration: underline;
    font-size: 16px;
}

.link-button-copied{
    font-size: 15px;
    text-decoration: underline;
    font-weight: bold;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    color: #a4a4a4e6;
}

.play-button{
   
    height: 50px !important;
     width: 50px !important;
     color: #779F60;
     cursor: pointer;
}
.play-button:hover{
    height: 52px !important;
    width: 52px !important;
}

.recording-information{
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 200px;
    padding: 0px 20px;

    &-title {
        font-size: 17px;
        color: #1c1c1c;
        font-weight: bold;
        text-transform: capitalize;
    }
    &-subtitle {
        font-size: 12px;
        color: #636363;
        font-weight: bold;
        text-transform: capitalize;
    }
    &-description {
        font-size: medium;
        color: rgb(127, 127, 127);
        margin: 0;

    }
    &-date {
        font-size: 17px;
        color: #1c1c1c;
        text-transform: capitalize;
        margin: 0;
        align-self: center;
    }
    &-time {
        font-size: medium;
        color: rgb(127, 127, 127);
        font-weight: bold;
        margin: 0;
        align-self: center;
    }
    &-size {
        font-size: medium;
        color: rgb(127, 127, 127);
        margin: 0;
        align-self: center;
    }

    &.strech{
        max-width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.share-recording-link-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: #88609F;
    border: none;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 0px;
  }
  
  .share-recording-link-item:hover img {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }