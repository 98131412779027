.header-create-meeting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

.start-meeting-tab {
  font-size: 12px !important;
  padding: 5px 10px;
  background: #779F60 !important;
  color: #fff !important;
  font-weight: bold !important;
  &:hover {
    background: #a4bf95 !important;
    color: #fff !important;
  }
}

.invite-meeting-tab {
  font-size: 12px !important;
  padding: 5px 10px;
  background: #404040 !important;
  color: #fff !important;
  font-weight: bold !important;
  &:hover {
    background: #696969 !important;
  }
  &:disabled {
    background: #9e9e9e !important;
    color: rgb(101, 101, 101) !important;
  }
}
