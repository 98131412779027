.dashboard-container {
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  gap: 15px;
}

.right-side-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tab-meetings-dashboard {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  border-radius: 5px;
}

.upcoming-meetings-container {
  height: 70vh !important;
  overflow: hidden;
  overflow-y: scroll;
}

.first-step-content {
  width: 100%;
  padding: 15px;
}

.select-products-container {
  width: 100% !important;
}

.select-info-meeting-container {
  width: 90% !important;
}

.review-meeting-container {
  background-color: #f5f5f5;
}

.meeting-info-header {
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 7px;
  padding: 10px;
}

.meeting-info-check {
  display: flex;
  align-items: center;
}

.images-info-container {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  gap: 10px;
  padding: 10px;
}

.images-info-container::-webkit-scrollbar {
  height: 5px !important;
}

.image-info-meeting {
  border-radius: 4px;
  border: 1px solid #dedede;
  cursor: pointer;
}

.shared-zoom-meeting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 15px;
  &-item {
    color: #73cce9;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #2e748b;
    }
  }
}

.content-form-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  &-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.shared-meeting-input {
  width: 65% !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  letter-spacing: 0.5px;
  border: 1px solid #ebd2da !important;
}

.btn-width-start {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
}

.btn-width-start-co {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
  background-color: #dedede !important;
  color: #2b2b2b !important;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #dededed2 !important;
    color: #6a6a6a !important;
  }
}

.btn-width-start-exit {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
  background-color: #333333 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #dedede !important;
    color: #2b2b2b !important;
  }
  &:disabled {
    background-color: #dedede !important;
    color: #686868 !important;
  }
}

.btn-width-start-delete {
  width: 30% !important;
  padding: 0px !important;
  height: 43px !important;
  background-color: #B01E55 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  &:hover {
    background-color: #dedede !important;
    color: #2b2b2b !important;
  }
  &:disabled {
    background-color: #dedede !important;
    color: #686868 !important;
  }
}

.form-inline-container {
  height: 20vh;
  overflow: hidden;
  gap: 0px !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b11e55;
  }
}

.content-btn-down {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}


.first {
  z-index: 10000;
}

.time {
  .css-1nf2ro8{
    z-index: 2000 !important;
  }
}