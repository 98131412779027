.header-detail-meeting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-top: 0.7px solid #c3c3c3;
  margin-top: 10px;
  &-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

.meeting-tab-options {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

.share-meeting-tab {
  font-size: 10px !important;
  padding: -2px !important;
  background: #404040 !important;
  color: #fff !important;
  width: auto !important;
  height: auto !important;
  font-weight: bold !important;
  &:hover {
    background: #696969 !important;
    color: rgb(255, 255, 255) !important;
  }
}

.edit-meeting-tab,
.delete-meeting-tab {
  font-size: 10px !important;
  padding: -2px !important;
  border: 1px solid#404040 !important;
  color: #404040 !important;
  width: auto !important;
  height: auto !important;
  font-weight: bold !important;
  &:hover {
    background: #bbbbbb !important;
    color: #404040 !important;
  }
}

.meeting-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px;
  border-top: 0.7px solid #c3c3c3;
  margin-top: 5px;

  &-info{
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      flex: 1 1 0%;
  }

  &-options{
      display: flex;
      flex-direction: column;
      column-gap: 6px;
      width: fit-content;
      padding: 0px 12px;
      
  }
}

.meeting-information{
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 180px;
  padding: 0px 20px 0px 0px;

  &-title {
      font-size: 17px;
      color: #1c1c1c;
      font-weight: bold;
      text-transform: capitalize;
  }
  &-subtitle {
      font-size: 12px;
      color: #636363;
      font-weight: bold;
      text-transform: capitalize;
  }
  &-description {
      font-size: medium;
      color: rgb(127, 127, 127);
      margin: 0;

  }
  &-date {
      font-size: 17px;
      color: #1c1c1c;
      text-transform: capitalize;
      margin: 0;
      align-self: center;
  }
  &-time {
      font-size: small;
      color: rgb(127, 127, 127);
      font-weight: bold;
      margin: 0;
      align-self: center;
  }

  &-link {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    color: #428bca;
    font-weight: bold;
    margin: 0;
    align-self: center;
}

  &.strech{
      max-width: fit-content;
      padding-left: 10px;
      padding-right: 10px;
  }
}

.in-progress-icon{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: rgb(153, 45, 45);
  width: 25px;
}

.parpadea {  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}


@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}