.checkout-working-live {
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 100%;
  z-index: 999;
  background-color: #ffffff;
  width: 32%;
  overflow: hidden;
  overflow-y: scroll;
  padding-left: 15px;
  padding-right: 30px;
  transition: transform 0.5s ease;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &-form-control {
    width: 100%;
  }
  &-products {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 14px;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 0px !important;
    }
  }
  &-total {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 12px;
  }
  &-email-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 12px;
  }
  &-email {
    width: 100%;
    padding: 10px 0px;
    &-form-input {
      padding-bottom: 8px;
      padding-top: 8px;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      font-size: 16px;
    }
  }
  &-check {
    display: flex;
    align-items: center;
  }
  &-btn-checkout {
    padding: 14px 0px;
    & button {
      cursor: pointer;
      background-color: #333333;
      color: #fff;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      border: 0;
      padding: 10px 10px;
      border-radius: 3px;
      &:hover {
        background-color: #565656;
      }
      &:disabled {
        background-color: #797979 !important;
        color: rgb(200, 200, 200) !important;
        cursor: not-allowed;
      }
    }
  }
  &-steps {
    display: flex;
    gap: 10px;
    flex-direction: column;
    border-top: 1px solid #d6d6d6;
    padding: 10px 0px;
  }
  &-next {
    padding: 10px 0px;
    width: 100%;
    &-input-address {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.delete-product-cross {
  background-color: rgba(220, 220, 220, 0.462);
  border-radius: 50%;
  padding: 5px;
  width: 10px !important;
  height: 10px !important;
  cursor: pointer;
  position: fixed;
  &:hover {
    background-color: rgba(220, 220, 220, 0.717);
  }
}

.add-borders {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  padding: 5px 0px;
  &-change {
    font-weight: bold;
    text-decoration: none;
    margin-left: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: rgba(0, 63, 94, 0.576);
    }
  }
}

.thank-you-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  &-text {
    padding: 15px 5px;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid #d2d2d2;
    border-bottom: 0.6px solid #d2d2d2;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.second-step {
  &-ship {
    padding: 18px 0px 0px 0px;
  }
}

.shipping-option {
  width: 100%;
  background-color: #f6f6f6;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #565656;
  border-radius: 4px;
  &:hover {
    background-color: #ededed;
  }
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: bold !important;
  display: flex;
}

#redirectLink {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 4px;
  margin: 20px auto 0 auto;
  height: 40px !important;
  font-size: 20px;
  background-color: #1cc48b;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transition: 200ms;
}

#redirectLink:hover {
  background-color: #19c687;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 200ms;
}

.invert-continue-shopping button {
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #d2d2d2 !important;
  background-color: #333333;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  width: 45%;
  text-align: center;
  border: 0;
  padding: 10px 10px;
  border-radius: 3px;
  &:hover {
    background-color: #e8e8e8 !important;
  }
  &:disabled {
    background-color: #797979 !important;
    color: rgb(200, 200, 200) !important;
  }
}

.invert-continue-shopping {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.container-btn-edit-cards {
  display: flex;
  gap: 10px;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.edit-btn-card {
  border: 0px;
  border-radius: 4px;
  padding: 5px 10px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  }
}
