@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Manrope:wght@400;600;700&family=Montserrat:wght@400;500;600;700&display=swap");

html,
.app,
.content {
  height: auto;
  width: 100%;
  font-family: "Lato", sans-serif;
  min-width: auto !important;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#zmmtg-root {
  display: none;
}

.MuiBox-root .pro-sidebar {
  min-width: 232px;
  width: 210px;
}

