.css-1nf2ro8 {
    z-index: 2000 !important;
}

.label-input-form {
    font-weight: bold;
    color: #6D7286;
    font-size: 13px;
}

.container-twoflex-options {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}


@media screen and (max-width: 750px) {
    .container-twoflex-options {
        grid-template-columns: 1fr;
    }
}

.btn-personalized-cancel {
    margin-top: 20px;
    background-color: #333333;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    cursor: pointer;
    font-size: 12px;
    border: none;
    outline: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 5px;
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 10px;
}



.btn-personalized-cancel:hover {
    background-color: #5c5c5c;
}