.container-modal {
    width: 100%;
    padding: 15px 35px;
    display: flex;
    flex-direction: column;
    
}

.container-modal input[type='text'], input[type='number']{
    box-sizing: content-box;
}

.container-modal-title{   
    width: 100%;;
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    justify-content: flex-start;
}

.modal-title{
    font-weight: bold;
    color: #1B5DCE;
    font-size: large;
}

.modal-title-icon {
    color: #6EA3FF;
    width: 4rem !important;
    height: 4rem !important;
}

.content-form-minimal-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
}

.container-twoflex-options{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}

.label-input-form{
    font-weight: bold;
    color: #6D7286;
    font-size: 12px;
}

.helper-text{
    margin: 0px !important;
    text-align: end !important;
    padding: 0px 10px;
}

.helper-text.error{
    color: rgb(180, 45, 45)
}

.banner-info {
    width: 100%;
    padding: 5px 10px;
    background-color: #F2F6FF;
    border: 1px solid;
    border-color: #DBE8FF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #7790db;
    column-gap: 6px;
    align-items: center;
}

.text-info{
    font-size: 14px;
    line-height: 14px;
    margin: 0px;
}

.icon-info{
    height: 20px;
    width: 20px;
}

.verify-title {
    font-size: 0.9rem;
    color: rgb(49, 49, 49);
    margin: 6px;
    font-weight: bold;
}

.verify-content {
    font-size: 0.9rem;
    color: black;
    margin: 0px;
    padding: 3px;
}


@media screen and (max-width: 850px){
    .text-info{
        font-size: 12px;
    }
    .icon-info{
        height: 10px;
        width: 10px;
    }   
}

@media screen and (max-width: 750px){
    .container-twoflex-options{
        grid-template-columns: 1fr;
    }
}