/*********** INDEX CSS STYLE COMMON FOR ZOOM MEETING SDK *************/
#start-my-meeting-swz {
    visibility: hidden;
}

.meeting-container-personalized {
    display: flex;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    flex-direction: row;
}

.column {
    display: none;
    flex-direction: column;
    position: relative;
    width: 20%;
}

.fullView-meeting-container {
    width: 100%;
    height: 100%;
}

/*******************/


/********* NEW REQUIREMENT STYLE **********/
.iframe-meeting-container.basic [class*="zmwebsdk-makeStyles-topbar-"] .zmwebsdk-MuiButton-textSizeSmall {
    width: 25px;
    height: 25px;
    padding: 3px 4px;
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-singleView-0>*:first-child {
    height: calc(100% - 35px) !important
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-singleView-0 .zmwebsdk-MuiPaper-elevation1 {
    height: fit-content
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-singleView-0 [class*="zmwebsdk-makeStyles-topbar-"] {
    height: fit-content !important
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-singleView-0 [class*="zmwebsdk-makeStyles-rootInSharing"] {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
}

.iframe-meeting-container.basic #suspension-view-tabpanel-ribbon>*:first-child {
    flex-direction: column-reverse;
}

.iframe-meeting-container.basic #suspension-view-tabpanel-ribbon [class*="zmwebsdk-makeStyles-wrap-"] {
    width: 100%;
    max-height: 100px;
}

.iframe-meeting-container.basic #suspension-view-tabpanel-ribbon [class*="zmwebsdk-makeStyles-wrap-"] ul {
    width: 100%;
}

.iframe-meeting-container.basic #suspension-view-tabpanel-ribbon [class*="zmwebsdk-makeStyles-wrap-"] [class*="zmwebsdk-makeStyles-avatarList"] {
    display: flex;
    flex-direction: row;
}

#suspension-view-tabpanel-ribbon {
    height: calc(100% - 35px) !important;
}

[id*="suspension-view-tabpanel-"] {
    height: calc(100% - 35px) !important;
}

[id*="suspension-view-tabpanel-"]>*:first-child {
    height: 100%;
}

#suspension-view-tabpanel-active>div:first-child {
    height: 100% !important;
}

[id*="suspension-view-tabpanel-"] [class*="zmwebsdk-makeStyles-wrap-"] {
    height: 100% !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"] {
    height: calc(100% - 100px) !important;
}

[class*="zmwebsdk-MuiToolbar-"]>*:first-child {
    width: auto !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"]>*:first-child {
    max-height: unset !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"] div canvas {
    width: 100% !important;
    height: 100% !important;
    height: fit-content;
    justify-content: center;
    object-fit: scale-down;
}

[class*="zmwebsdk-makeStyles-inSharing-"] .zmwebsdk-MuiBox-root {
    left: unset !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"] .zmwebsdk-MuiBox-root button {
    display: none;
}

.react-draggable>*:first-child {
    display: flex;
    flex-direction: column;
}

[class*="zmwebsdk-makeStyles-videoCustomize-"].react-draggable {
    position: initial !important;
}

[class*="zmwebsdk-makeStyles-inSharing-"] .zmwebsdk-MuiBox-root {
    max-height: fit-content !important;
    width: 100% !important;
    max-width: fit-content !important;
    height: fit-content !important;
    align-self: unset;
    vertical-align: unset;
}

[class*="zmwebsdk-makeStyles-isSharing-"].react-draggable>*:first-child {
    display: flex;
    flex-direction: row;
    width: fit-content !important;
    background: #88609fa6;
}

[class*="zmwebsdk-makeStyles-isSharing-"].react-draggable {
    display: flex;
    flex-direction: column;
    width: fit-content !important;
    background-color: transparent !important;
    max-width: fit-content;
}

#suspension-view-tabpanel-ribbon>*:first-child {
    height: 100% !important;
}

#suspension-view-tabpanel-ribbon:not(:has([class*="zmwebsdk-makeStyles-inSharing-"])) [class*="zmwebsdk-makeStyles-wrap-"] {
    max-height: 85% !important;
}

/*CHAT */
[class*="zmwebsdk-makeStyles-chatCustomize-"] [class*="zmwebsdk-makeStyles-list-"] {
    /* height: min(90vh, 486px) !important; */
    height: calc(100vh - 260px) !important;
}

[class*="zmwebsdk-makeStyles-chatCustomize-"] textarea{
    padding: 2px 5px;
    font-size: 12px !important;
    line-height: 16px;
}

[class*="zmwebsdk-makeStyles-chatCustomize-"] .ReactVirtualized__Grid__innerScrollContainer li span{
    font-size: 1rem ;
}

[class*="zmwebsdk-makeStyles-chatCustomize"]>.zmwebsdk-MuiPaper-root:first-child {
    width: 100% !important;
    transform: translate(0px, 0px) !important;
    height: min-content !important;
    min-width: unset;
    border-radius: 0px;
    border-color: black;
}

[class*="zmwebsdk-makeStyles-chatCustomize-"] {
    top: 62px !important;
    right: 5px;
    width: 20%;
    left: unset !important;
    transform: translate(0px, 0px) !important;
}

/*PARTICIPANTS */
[class*="zmwebsdk-makeStyles-participantsCustomize-"] [class*="zmwebsdk-makeStyles-list-"] {
    /* height: min(90vh, 561px) !important; */
    height: calc(100vh - 150px) !important;
}

[class*="zmwebsdk-makeStyles-participantsCustomize"]>.zmwebsdk-MuiPaper-root:first-child {
    width: 100% !important;
    transform: translate(0px, 0px) !important;
    height: min-content !important;
    min-width: unset;
    border-radius: 0px;
    border-color: black;
}

[class*="zmwebsdk-makeStyles-participantsCustomize-"] {
    top: 62px !important;
    right: 5px;
    width: 20%;
    left: unset !important;
    transform: translate(0px, 0px) !important;
}

[class*="zmwebsdk-makeStyles-participantsCustomize-"] .ReactVirtualized__List {
    width: 100% !important;
}

[class*="zmwebsdk-makeStyles-participantsCustomize-"] .ReactVirtualized__List:first-child {
    max-width: unset !important;
}

/*SI ESTA ABIERTO PARTICIPANTES O CHAT SE REDUCE EL ESPACIO DE VIDEO */
body:has([class*="zmwebsdk-makeStyles-participantsCustomize-"]) .column {
    display: flex;
}

body:has([class*="zmwebsdk-makeStyles-chatCustomize-"]) .column {
    display: flex;
}


[class*="zmwebsdk-makeStyles-topbar-"] .zmwebsdk-MuiButton-iconSizeSmall>*:first-child {
    font-size: 18px !important;
    padding: 2px;
}

[class*="zmwebsdk-makeStyles-participantsCustomize-"] li {
    width: 97% !important;
}

/* CINTA DE OPCIONES */
button[title="Participants"] h6{
    font-size: 1rem;
}
button[title="Participants"] g{
    clip-path: unset ;
}
/*********************************/

/********* OLD STYLES **********/
#meetingSDKElement {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px;
    flex: 1 1 0%;
    justify-content: center;
}

.zmwebsdk-makeStyles-videoCustomize-8 {
    width: 60%;
}

.zmwebsdk-makeStyles-wrap-44,
.zmwebsdk-makeStyles-wrap-49 {
    width: 100% !important;
}

@media (max-width: 420px) {

    .iframe-meeting-container.basic #meetingSDKElement {
        height: 100%;
    }

    .zmwebsdk-makeStyles-videoCustomize-8 {
        width: 100%;
    }
}

/**************************/




/********* FIRST STYLES **********/
.iframe-meeting-container.basic {
    height: calc(100vh - 57px) !important;
    height: calc(100dvh - 57px) !important;
    width: 100%;
    background-color: black;
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-videoCustomize-1 {
    height: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
}

.iframe-meeting-container.basic .zmwebsdk-MuiPaper-rounded {
    border-radius: 0px !important;

}

.iframe-meeting-container.basic .react-draggable {
    width: 100% !important;
    background-color: black;
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-singleView-7 {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-root-30 {
    flex: 1 1 0%;
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-root-27 {
    height: 100%;
}

.iframe-meeting-container.basic .zmwebsdk-MuiPaper-elevation1 {
    width: 100% !important;
    padding: 0;
    background-color: black;
}

.iframe-meeting-container.basic .zoommtg-drag-video .zmwebsdk-MuiBox-root {
    max-width: none;
}

.iframe-meeting-container.basic .zmwebsdk-makeStyles-root-248 {
    height: 100% !important;
    width: 100% !important;
    max-width: 600px;
    max-height: 400px;
}

.iframe-meeting-container.basic .zmwebsdk-MuiBox-root {
    height: 100%;
    width: 100%;
    max-width: 600px;
    max-height: 400px;
    align-self: center;
    vertical-align: middle;
}


.iframe-meeting-container.basic .zmwebsdk-MuiButton-iconSizeSmall>*:first-child {
    font-size: 22px;
}

.iframe-meeting-container.basic .zmwebsdk-MuiButton-textSizeSmall {
    width: 45PX;
    HEIGHT: 35PX;
}

#suspension-view-tabpanel-active>*:first-child {
    display: flex;
    position: relative;
    justify-content: center;
}

[class*="zmwebsdk-makeStyles-topbar-"]>*:first-child {
    width: auto !important;
}


/********* RESPONSIVE STYLES **********/
@media only screen and (max-width : 900px) {
    .column {
        display: none;
    }

    /*SI ESTA ABIERTO PARTICIPANTES O CHAT NO SE REDUCE EL SIZE DEL VIDEO*/
    body:has([class*="zmwebsdk-makeStyles-participantsCustomize-"]) .column {
        display: none;
    }

    body:has([class*="zmwebsdk-makeStyles-chatCustomize-"]) .column {
        display: none;
    }
}

@media only screen and (max-width : 900px) {
    [class*="zmwebsdk-makeStyles-chatCustomize"]>.zmwebsdk-MuiPaper-root:first-child {
        min-width: 92vw !important;
    }

    [class*="zmwebsdk-makeStyles-chatCustomize-"] {
        width: calc(100% - 10px);
        left: 5px;
        transform: translate(0px, 0px) !important;
        bottom: 10px;
        top: unset !important;
    }

    [class*="zmwebsdk-makeStyles-participantsCustomize"]>.zmwebsdk-MuiPaper-root:first-child {
        min-width: 92vw !important;
    }

    [class*="zmwebsdk-makeStyles-participantsCustomize-"] {
        width: calc(100% - 10px);
        left: 5px;
        transform: translate(0px, 0px) !important;
        bottom: 10px;
        top: unset !important;
    }
}

@media (orientation: landscape) and (max-width: 900px) {}

@media only screen and (max-width : 600px) {
    [class*="zzmwebsdk-makeStyles-wrap-"] li {
        width: 100% !important;
        height: 100% !important;
        top: auto;
        left: auto;
    }

    [class*="zmwebsdk-makeStyles-buttonsContainer-"]:has(button[title="Show captions"]) {
        display: none;
    }

    button[title="Show captions"] {
        display: none;
    }

    button[title="Caption settings"] {
        display: none;
    }

    #suspension-view-tabpanel-ribbon {
        height: calc(100% - 21px) !important;
    }

    .iframe-meeting-container.basic .zmwebsdk-MuiButton-textSizeSmall {
        width: 30PX;
        HEIGHT: 30PX;
    }

    [class*="zmwebsdk-makeStyles-inSharing-"] {
        display: block;
    }

    span[class*="zmwebsdk-MuiButton-iconSizeSmall"] svg {
        font-size: 25px !important;
    }
}

@media screen and (max-width: 420px) {
    span[class*="zmwebsdk-MuiButton-iconSizeSmall"] svg {
        font-size: 22px !important;
    }
}

@media (orientation: landscape) and (max-width: 900px) {
    .header-title-container {
        display: none;
    }

    #suspension-view-tabpanel-ribbon:has([class*="zmwebsdk-makeStyles-inSharing-"]) [class*="zmwebsdk-makeStyles-wrap-"] {
        display: none;
    }

    #suspension-view-tabpanel-ribbon [class*="zmwebsdk-makeStyles-inSharing-"] [class*="zmwebsdk-makeStyles-wrap-"] {
        display: flex;
    }

    .iframe-meeting-container.basic {
        height: 100vh !important;
    }

    .zmwebsdk-makeStyles-singleView-0 [class*="zmwebsdk-makeStyles-topbar-"] {
        padding: 2px !important;
    }

    .iframe-meeting-container.basic [class*="zmwebsdk-makeStyles-topbar-"] .zmwebsdk-MuiButton-textSizeSmall {
        padding: 2px;

    }
}